// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import * as projectServerActions from 'common/modules/computeResourceVm/actions';
import * as snapshotActions from 'common/modules/snapshot/actions';
import * as projectActions from 'common/modules/project/actions';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import SnapshotsTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/SnapshotsTab/SnapshotsTab';
import { isServerSectionAvailable } from 'client/project/selectors/server';
import { TabsExpander } from 'client/common/styles/Tabs';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import {
    Action,
    Icon,
    Label,
    Popover,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import OverviewTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/OverviewTab/OverviewTab';
import GraphsTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/GraphsTab/GraphsTab';
import ViewSelect from 'common/components/ViewSelect';
import {
    ICONS,
    INTENT_TYPE,
    VIEW_TYPE,
} from 'common/constants';
import { setViewType } from 'common/modules/app/viewType/actions';
import { TABS } from 'common/modules/project/constants';
import {
    BootMode,
    ComputeResourceVmStatus,
    isBackupEnabled,
} from 'common/api/resources/ComputeResourceVm';
import PageHeader from 'common/components/PageHeader/PageHeader';
import {
    ActionContainer,
    MainTitleWithActionSubContainer,
    PageName,
    PageSection,
} from 'common/components/PageHeader/Styles';
import { VmStatus } from 'common/components/VmStatus';
import ProjectServerActions from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerHeader/ProjectServerActions';
import ProjectServerDescription from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerHeader/ProjectServerDescription';
import RescueTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/RescueTab/RescueTab';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import VmBackupsTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/BackupTab/VmBackupsTab';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import ReinstallTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/ReinstallTab/ReinstallTab';
import ResizeTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/ResizeTab/ResizeTab';
import NetworkingTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/NetworkingTab/NetworkingTab';
import SnapshotForm from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerHeader/SnapshotForm';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import { withTheme } from 'styled-components';
import { Theme } from 'common/styles/variables';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import TabStatus from 'common/components/ServerTabs/components/TabStatus/TabStatus';
import { areNetworkLimitsExceeded } from 'common/api/resources/Plan';
import { PROJECT_SERVER_ITEM } from 'client/project/constants/tests';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import SettingsTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/SettingsTab/SettingsTab';
import { initialComputeResourceVmState } from 'common/modules/computeResourceVm/reducers';
import InternalError from 'common/components/ErrorPages/InternalError/InternalError';
import { HTTP_CODES } from 'common/api/constants';
import DisksTab from 'client/project/containers/projectServer/ProjectServerItem/tabs/DisksTab/DisksTab';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { AlertMessage } from 'client/common/components';

export type ProjectServerItemProps =
    IProjectServerItemProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IProjectServerItemProps extends RouteComponentProps<{ id: string; vmId: string }> {
    theme: Theme;
}

enum POPOVERS {
    SNAPSHOT = 'snapshot'
}

export const ProjectServerItem: React.FC<ProjectServerItemProps> = ({
    selectedView,
    setSelectedView,
    isLoading,
    isBackupCreating,
    areSnapshotsAvailable,
    canResizeComputeResourceVm,
    canManageComputeResourceVms,
    areLimitsExceeded,
    match: { params },
    location: { hash },
    history,
    projectServerActions: {
        getComputeResourceVm,
        createBackup,
    },
    server,
    isSectionAvailable,
    projectActions: {
        getProject,
        getProjectTokenPricing,
    },
    isUserLockedOrSuspended: userLockedOrSuspended,
    userStatus,
    theme,
    canCreateServersSnapshot,
    canViewBootRescueTab,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState({
        [POPOVERS.SNAPSHOT]: false,
    });
    const [internalError, setInternalError] = React.useState(false);

    const handleOpen = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: true });
    const handleClose = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: false });

    const serverId = parseInt(params.vmId, 10);
    const projectId = parseInt(params.id, 10);

    const isNotInMaintenance = !server.compute_resource?.is_in_maintenance;

    const tabs = Object.values(TABS).filter(tab =>
        (server.virtualization_type === VirtualizationType.VZ || tab !== TABS.SETTINGS)
        && (areSnapshotsAvailable || tab !== TABS.SNAPSHOTS)
        && (server.is_backup_available || tab !== TABS.BACKUP)
        && (canResizeComputeResourceVm || tab !== TABS.RESIZE)
        && (server.virtualization_type === VirtualizationType.KVM || tab !== TABS.DISKS)
    );

    if ((server.id !== initialComputeResourceVmState.id) && (!hash || tabs.every(tab => tab !== hash))) {
        history.replace(tabs[0]);
    }

    const onMount = async () => {
        try {
            await getProject(projectId);
            await Promise.all([
                getComputeResourceVm(serverId),
                getProjectTokenPricing(projectId),
            ]);
        } catch (e) {
            if (e.response.status === HTTP_CODES.INTERNAL) {
                setInternalError(true);
            }
            throw e;
        }
    };

    const handleTabSelect = (index: number) => history.replace(tabs[index]);
    const handleViewChange = (value: string) => setSelectedView(VIEW_TYPE[value]);
    const handleCreateBackup = () => { createBackup(server.id); };

    const renderHeaderButton = () => {
        switch (hash) {
        case TABS.SNAPSHOTS:
            return canCreateServersSnapshot ? (
                <Popover
                    onClose={handleClose(POPOVERS.SNAPSHOT)}
                    visible={isPopoverOpened[POPOVERS.SNAPSHOT]}
                    canCloseOnOutsideClick={false}
                    target={
                        <HeaderButton
                            isMobile={true}
                            onClick={handleOpen(POPOVERS.SNAPSHOT)}
                            icon={ICONS.CAMERA}
                            tooltip={userLockedOrSuspended && (
                                <Translate content="projects.lockedOrSuspendedUserTooltip" params={{ status: userStatus }}/>
                            )}
                            isLoading={server.status === ComputeResourceVmStatus.SNAPSHOT_CREATING || server.isLoading}
                            disabled={userLockedOrSuspended || server.is_processing}
                        >
                            <Translate content="snapshots.createSnapshot" />
                        </HeaderButton>
                    }
                    placement="bottom-left"
                >
                    <SnapshotForm onCreated={handleClose(POPOVERS.SNAPSHOT)} serverId={server.id}/>
                </Popover>
            ) : null;

        case TABS.BACKUP:
            const createTooltip = server.boot_mode === BootMode.RESCUE ? (
                <Translate content="projects.server.backups.createBtn.disabledInRescueModeTooltip" />
            ) : (
                <Translate content="projects.server.backups.createBtn.tooltip" />
            );

            return (
                <ButtonWithConfirmation
                    icon="backup"
                    isLoading={isBackupCreating}
                    handleConfirm={handleCreateBackup}
                    confirmationButtonText={<Translate content="projects.server.backups.createBtn.title"/>}
                    confirmationButtonGhost={false}
                    buttonIntent={INTENT_TYPE.PRIMARY}
                    buttonColor={theme.primary_color}
                    withStyledButton={true}
                    buttonSize="lg"
                    disabled={server.is_processing || !isBackupEnabled(server) || server.boot_mode === BootMode.RESCUE}
                    translations={{
                        title: (
                            <Translate content="projects.server.backups.createBtn.title" />
                        ),
                        text: (
                            <Translate content="projects.server.backups.createBtn.text" />
                        ),
                        button: (
                            <Translate content="projects.server.backups.createBtn.button" />
                        ),
                        tooltip: createTooltip,
                    }}
                    data-cy={ACTIONS.CREATE_BUTTON}
                />
            );
        }
        return null;
    };

    if (internalError) {
        return (<InternalError canSeeActualError={canManageComputeResourceVms} />);
    }

    return (
        <WithErrorPages onMount={onMount} deps={[serverId, projectId]}>
            {isSectionAvailable && (
                <Loader isLoading={isLoading}>
                    {!isNotInMaintenance && (
                        <AlertMessage
                            title={<Translate content="servers.status.inMaintenance" />}
                            isError={true}
                        />
                    )}
                    <PageHeader
                        title={(
                            <MainTitleWithActionSubContainer>
                                <PageSection>
                                    <Action
                                        component={Link}
                                        to={pathTo(`projects/${server.project.id}#servers`)}
                                    >
                                        {server.project.name}
                                    </Action>
                                    <Icon name="chevron-right" />
                                </PageSection>
                                <Tooltip title={<span style={{ whiteSpace: 'nowrap' }}>{server.name}</span>}>
                                    <PageName><span>{server.name}</span></PageName>
                                </Tooltip>
                                <ActionContainer style={{ marginRight: '16px' }}>
                                    <VmStatus
                                        status={server.status}
                                        isProcessing={server.is_processing}
                                        progress={server.progress}
                                    />
                                </ActionContainer>
                                {server.is_suspended && (
                                    <ActionContainer>
                                        <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended" /></Label>
                                    </ActionContainer>
                                )}
                            </MainTitleWithActionSubContainer>
                        )}
                        subtitle={<ProjectServerDescription />}
                        actions={<ProjectServerActions server={server} iconSize="24" />}
                        actionButton={renderHeaderButton()}
                    />
                    <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                        <TabList>
                            <Tab>
                                <Translate content="projects.server.tabs.overview" />
                            </Tab>
                            {isNotInMaintenance && server.virtualization_type === VirtualizationType.VZ && (
                                <Tab>
                                    <Translate content="projects.server.tabs.settings" />
                                </Tab>
                            )}
                            <Tab>
                                <Translate content="projects.server.tabs.graphs" />
                            </Tab>
                            {isNotInMaintenance && areSnapshotsAvailable && (
                                <Tab>
                                    <Translate content="projects.server.tabs.snapshots" />
                                </Tab>
                            )}
                            {isNotInMaintenance && canViewBootRescueTab && (
                                <Tab>
                                    <Translate content="projects.server.tabs.rescue" />
                                </Tab>
                            )}
                            {isNotInMaintenance && server.is_backup_available && (
                                <Tab>
                                    <Translate content="projects.server.tabs.backup" />
                                </Tab>
                            )}
                            {isNotInMaintenance && (
                                <Tab>
                                    <Translate content="projects.server.tabs.reinstall" />
                                </Tab>
                            )}
                            {isNotInMaintenance && canResizeComputeResourceVm && (
                                <Tab>
                                    <Translate content="projects.server.tabs.resize" />
                                </Tab>
                            )}
                            {isNotInMaintenance && (
                                <Tab>
                                    {areLimitsExceeded && (
                                        <TabStatus
                                            data-cy={PROJECT_SERVER_ITEM.TAB_STATUS}
                                            intent={INTENT_TYPE.WARNING}
                                        />
                                    )}
                                    <Translate content="projects.server.tabs.networking" />
                                </Tab>
                            )}
                            {isNotInMaintenance && server.virtualization_type === VirtualizationType.KVM && (
                                <Tab>
                                    <Translate content="projects.server.tabs.disks" />
                                </Tab>
                            )}
                            <TabsExpander />
                            {isNotInMaintenance && hash === TABS.SNAPSHOTS && (
                                <ViewSelect
                                    selected={selectedView}
                                    onChange={handleViewChange}
                                />
                            )}
                        </TabList>
                        <TabPanel>
                            <OverviewTab />
                        </TabPanel>
                        {isNotInMaintenance && server.virtualization_type === VirtualizationType.VZ && (
                            <TabPanel>
                                <SettingsTab />
                            </TabPanel>
                        )}
                        <TabPanel>
                            <GraphsTab />
                        </TabPanel>
                        {isNotInMaintenance && areSnapshotsAvailable && (
                            <TabPanel>
                                <SnapshotsTab serverId={serverId} />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && canViewBootRescueTab && (
                            <TabPanel>
                                <RescueTab />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && server.is_backup_available && (
                            <TabPanel>
                                <VmBackupsTab />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && (
                            <TabPanel>
                                <ReinstallTab />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && canResizeComputeResourceVm && (
                            <TabPanel>
                                <ResizeTab />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && (
                            <TabPanel>
                                <NetworkingTab />
                            </TabPanel>
                        )}
                        {isNotInMaintenance && server.virtualization_type === VirtualizationType.KVM && (
                            <TabPanel>
                                <DisksTab />
                            </TabPanel>
                        )}
                    </Tabs>
                </Loader>
            )}
        </WithErrorPages>
    );
};

const mapStateToProps = (state: RootState) => ({
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
    userStatus: state.auth.user.status,
    selectedView: state.app.viewType,
    project: state.project.projects.item,
    areSnapshotsAvailable: state.project.servers.item.plan.is_snapshot_available && state.project.servers.item.plan.is_snapshots_enabled,
    areLimitsExceeded: areNetworkLimitsExceeded(state.project.servers.item),
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM),
    isBackupCreating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_CREATE) ||
        state.project.servers.item.status === ComputeResourceVmStatus.BACKUP_CREATING,
    isSectionAvailable: isServerSectionAvailable(state),
    server: state.project.servers.item,
    canResizeComputeResourceVm: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS, PERMISSION_LIST.RESIZE_SERVERS),
    canCreateServersSnapshot: hasPermission(state, PERMISSION_LIST.CREATE_SERVERS_SNAPSHOT),
    canManageComputeResourceVms: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    canViewBootRescueTab: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.RESET_ROOT_PASSWORD,
        PERMISSION_LIST.CHANGE_SERVERS_BOOT_MODE
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectServerActions: bindActionCreators(projectServerActions, dispatch),
    snapshotActions: bindActionCreators(snapshotActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch),
    setSelectedView: bindActionCreators(setViewType, dispatch),
});

export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(ProjectServerItem)));
