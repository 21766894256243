// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import * as computeResourceActions from 'admin/computeResource/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { NOT_SELECTED_ID } from 'common/api/constants';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToList,
    addToPaginated,
} from 'common/reducers/list';
import {
    IComputeResourceCapabilities,
    IComputeResourceMetricsResponse,
    IComputeResourceResponse,
    IComputeResourceSettingsResponse,
    ILogicalVolumeResponse,
    IMetricResponse,
    INetworkResponse,
    IPhysicalVolumeResponse,
    NetworkType,
    IUsageResponse,
    IShortComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { IStorageResponse } from 'common/api/resources/Storage';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';

interface IComputeResourceReducer {
    item: IComputeResourceResponse;
    list: IPaginateApiResponse<IComputeResourceResponse[]>;
    ipBlocks: IIpBlockResponse[];
    networks: INetworkResponse[];
    metrics: IMetricResponse[];
    physicalVolumes: IPhysicalVolumeResponse[];
    thinPools: ILogicalVolumeResponse[];
    storages: IStorageResponse[];
    usage: IUsageResponse;
}

export type ComputeResourceAction = ActionType<typeof computeResourceActions>;
export type ComputeResourceState = Readonly<IComputeResourceReducer>;

export const initialSettingsState: IComputeResourceSettingsResponse = {
    iso_path: '',
    cache_path: '',
    backup_tmp_path: '',
    vnc_proxy_port: 7778,
    balance_strategy: '',
    network: {
        type: NetworkType.ROUTED,
        bridges: [],
    },
    virtualization_types: [],
    limits: {
        vm: { total: 0, used: 0, unlimited: true },
        hdd: { total: 0, used: 0, unlimited: true },
        ram: { total: 0, used: 0, unlimited: true },
        vcpu: { total: 0, used: 0, unlimited: true },
    },
    vs_disk_cache_mode: null,
    concurrent_backups: {
        create: 1,
        restore: 1,
    },
};

export const initialMetricsState: IComputeResourceMetricsResponse = {
    network: {
        ipv6_available: false,
    },
};

export const initialUsageState: IUsageResponse = {
    server_count: 0,
    cpu: 0,
    ram: 0,
    disk: 0,
};

export const initialCapabilitiesState: IComputeResourceCapabilities = {
    kvm: false,
    vz: false,
    is_management_node: false,
    is_mem_balloon_free_page_reporting_supported: false,
    is_virtio_discard_supported: false,
};

export const initialShortComputeResourceState: IShortComputeResourceResponse = {
    id: NOT_SELECTED_ID,
    name: '',
    host: '',
    capabilities: initialCapabilitiesState,
    is_in_maintenance: false,
};

export const initialComputeResourceState: IComputeResourceResponse = {
    id: NOT_SELECTED_ID,
    status: COMPUTE_RESOURCE_STATUS.COMMISSIONING,
    name: '',
    host: '',
    agent_port: 8080,
    vms_count: 0,
    version: '',
    locations: [],
    settings: initialSettingsState,
    metrics: initialMetricsState,
    is_configuring_network: false,
    is_deleting: false,
    capabilities: initialCapabilitiesState,
    is_locked: false,
    is_in_maintenance: false,
    storages:[],
};

export default combineReducers<ComputeResourceState, ComputeResourceAction>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(computeResourceActions.appendComputeResources):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(computeResourceActions.setList):
            return action.payload;
        case getType(computeResourceActions.addItem):
            return addToPaginated(state, action.payload);
        case getType(computeResourceActions.updateItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }

                    return item;
                }),
            };
        case getType(computeResourceActions.setSettings):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        item.settings = action.payload.data;
                        return item;
                    }

                    return item;
                }),
            };
        case getType(computeResourceActions.setInstallSteps):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        item.installSteps = action.payload.data;
                        return item;
                    }

                    return item;
                }),
            };
        case getType(computeResourceActions.updateInstallStep):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.compute_resource_id) {
                        let installSteps;
                        if (!item.installSteps) {
                            installSteps = [ action.payload ];

                            return { ...item, installSteps };
                        }

                        const idx = item.installSteps.findIndex((step) => step.id === action.payload.id);

                        if (idx === -1) {
                            installSteps = [ ...item.installSteps, action.payload ];
                        } else {
                            installSteps = [ ...item.installSteps ];
                            installSteps[idx] = action.payload;
                        }

                        return { ...item, installSteps };
                    }

                    return item;
                }),
            };
        case getType(computeResourceActions.deleteItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(computeResourceActions.setCRItemIsConfiguringNetwork):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_configuring_network: true };
                    }

                    return item;
                })],
            };
        case getType(computeResourceActions.unsetCRItemIsConfiguringNetwork):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_configuring_network: false };
                    }

                    return item;
                })],
            };
        case getType(computeResourceActions.setCRItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = { ...initialComputeResourceState }, action) => {
        switch (action.type) {
        case getType(computeResourceActions.setItem):
            return action.payload;
        case getType(computeResourceActions.setItemId):
            return { ...state, id: action.payload };
        case getType(computeResourceActions.unsetItem):
            return { ...initialComputeResourceState };
        case getType(computeResourceActions.setSettings):
            if (action.payload.id === state.id) {
                return { ...state, settings: action.payload.data };
            }

            return state;
        case getType(computeResourceActions.updateItem):
            if (action.payload.id === state.id) {
                return action.payload;
            }
            // fallthrough
        case getType(computeResourceActions.setCRItemIsConfiguringNetwork):
            if (state.id === action.payload) {
                return { ...state, is_configuring_network: true };
            }
            // fallthrough
        case getType(computeResourceActions.unsetCRItemIsConfiguringNetwork):
            if (state.id === action.payload) {
                return { ...state, is_configuring_network: false };
            }
            // fallthrough
        default:
            return state;
        }
    },
    networks: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.setNetworks):
            return action.payload;
        default:
            return state;
        }
    },
    metrics: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.setMetrics):
            return action.payload;
        default:
            return state;
        }
    },
    physicalVolumes: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.setPhysicalVolumes):
            return action.payload;
        default:
            return state;
        }
    },
    thinPools: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.setThinPools):
            return action.payload;
        default:
            return state;
        }
    },
    usage: (state = initialUsageState, action) => {
        switch (action.type) {
        case getType(computeResourceActions.setUsage):
            return action.payload;
        default:
            return state;
        }
    },
    storages: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.deleteStorage):
            return state.filter(item => item.id !== action.payload);
        case getType(computeResourceActions.setStorages):
            return action.payload;
        case getType(computeResourceActions.patchStorage):
            return state.map(item => item.id === action.payload.id ? action.payload : item);
        case getType(computeResourceActions.addStorage):
            return addToList(action.payload, state);
        case getType(computeResourceActions.setCRStorageItemIsDeleting):
            return state.map(item => {
                if (item.id === action.payload.id) {
                    return { ...item, isDeleting: action.payload.isDeleting };
                }

                return item;
            });
        default:
            return state;
        }
    },
    ipBlocks: (state = [], action) => {
        switch (action.type) {
        case getType(computeResourceActions.setIpBlocks):
            return action.payload;
        default:
            return state;
        }
    },
});
