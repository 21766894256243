// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import {
    Button,
    Icon,
    Popover,
    Text,
    Translate,
} from '@plesk/ui-library';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { RootState } from 'client/core/store';
import {
    ICONS,
    INTENT_TYPE,
    VNC_DIALOG_SIZE,
} from 'common/constants';
import { popupCenter } from 'common/helpers/popup';
import {
    ComputeResourceVmStatus,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import * as projectServerActions from 'common/modules/computeResourceVm/actions';
import { HTTP_CODES } from 'common/api/constants';
import ProjectServerForm from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/ProjectServerForm';
import { dataCySelector } from 'common/tests/selectors';
import { SERVER } from 'admin/computeResource/constants/tests';
import ApplicationLoginLink from 'client/project/components/ApplicationLoginLink/ApplicationLoginLink';
import { Theme } from 'common/styles/variables';
import { withTheme } from 'styled-components';
import ButtonWithInputConfirmation
    from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface IProjectServerActionsProps {
    server: IVmResponse;
    iconSize: string;
    theme: Theme;
}

export type ProjectServerActionsProps =
    IProjectServerActionsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

enum POPOVERS {
    EDIT = 'edit',
}

export const ProjectServerActions: React.FunctionComponent<ProjectServerActionsProps> = ({
    server,
    push,
    iconSize,
    projectServerActions: {
        restartComputeResourceVm,
        deleteComputeResourceVm,
        stopComputeResourceVm,
        startComputeResourceVm,
    },
    theme,
    user,
    canManageServers,
    canStartServers,
    canStopServers,
    canRestartServers,
    canPowerOffServers,
    canDeleteServers,
    canOpenVncConsole,
    canChangeServersHostname,
}) => {
    const [startError, setStartError] = React.useState<string>();
    const [isPopoverOpened, setPopoverOpened] = React.useState({
        [POPOVERS.EDIT]: false,
    });

    const handleOpen = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: true });
    const handleClose = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: false });

    const handleShowVnc = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        popupCenter(`/vnc_client/${server.id}`, VNC_DIALOG_SIZE.WIDTH, VNC_DIALOG_SIZE.HEIGHT);
    };
    const handleReboot = () => restartComputeResourceVm(server.id, { force: false });
    const handleShutdown = () => stopComputeResourceVm(server.id, { force: false });
    const handlePowerOff = () => stopComputeResourceVm(server.id, { force: true });

    const handleDelete = async () => {
        try {
            await deleteComputeResourceVm(server.id);
            push(`/projects/${server.project.id}#servers`);
        // eslint-disable-next-line no-empty
        } catch (e) { }
    };

    const handleStart = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setStartError('');

        try {
            await startComputeResourceVm(server.id);
        } catch (err) {
            if (err.response.status === HTTP_CODES.BAD_REQUEST) {
                setStartError(err.response.data.message);
            }
        }
    };

    const isNotStarted = server.real_status !== ComputeResourceVmStatus.STARTED;

    const isOwner = user.id === server.user.id;

    const canDelete = canDeleteServers && (isOwner || canManageServers);

    const crIsInMaintenance = server.compute_resource?.is_in_maintenance;

    return (
        <>
            {(canManageServers || (isOwner && canStartServers)) && (
                <Popover
                    placement="bottom-right"
                    visible={!!startError}
                    intent={INTENT_TYPE.DANGER}
                    target={
                        <Button
                            ghost={true}
                            icon={<Icon name={ICONS.START_CIRCLE} size={iconSize} />}
                            disabled={crIsInMaintenance || !isNotStarted || server.is_suspended}
                            onClick={handleStart}
                            tooltip={<Translate content="projects.server.links.start" />}
                            data-cy={dataCySelector(server.id, SERVER.ACTIONS.START)}
                        />
                    }
                >
                    {startError}
                </Popover>
            )}
            {canOpenVncConsole && (
                <Button
                    ghost={true}
                    disabled={crIsInMaintenance || isNotStarted}
                    icon={<Icon name="console-filled" size={iconSize} />}
                    onClick={handleShowVnc}
                    tooltip={<Translate content="projects.server.links.vnc" />}
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.VNC)}
                />
            )}
            {(canManageServers || (isOwner && canRestartServers)) && (
                <ButtonWithConfirmation
                    icon={<Icon name={ICONS.RESET} size={iconSize} />}
                    disabled={crIsInMaintenance || isNotStarted}
                    translations={{
                        text: (
                            <Translate content="projects.server.restartConfirmation" />
                        ),
                        button: (
                            <Translate content="projects.server.confirmRestart" />
                        ),
                        title: (
                            <Translate content="projects.server.restartTitle" />
                        ),
                        tooltip: (
                            <Translate content="projects.server.restart" />
                        ),
                    }}
                    handleConfirm={handleReboot}
                    buttonColor={theme.primary_color}
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.RESTART)}
                />
            )}
            {(canManageServers || (isOwner && canStopServers)) && (
                <ButtonWithConfirmation
                    icon={<Icon name={ICONS.STOP_CIRCLE} size={iconSize} />}
                    disabled={crIsInMaintenance || isNotStarted}
                    translations={{
                        text: (
                            <Translate content="projects.server.shutdownConfirmation" />
                        ),
                        button: (
                            <Translate content="projects.server.confirmStop" />
                        ),
                        title: (
                            <Translate content="projects.server.stopTitle" />
                        ),
                        tooltip: (
                            <Translate content="projects.server.shutdown" />
                        ),
                    }}
                    handleConfirm={handleShutdown}
                    buttonColor={theme.primary_color}
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.STOP)}
                />
            )}
            {(canManageServers || (isOwner && canPowerOffServers)) && (
                <ButtonWithConfirmation
                    icon={<Icon name={ICONS.POWER} size={iconSize} />}
                    disabled={crIsInMaintenance || isNotStarted}
                    translations={{
                        text: (
                            <Translate content="projects.server.powerOffConfirmation" />
                        ),
                        button: (
                            <Translate content="projects.server.confirmPowerOff" />
                        ),
                        title: (
                            <Translate content="projects.server.powerOffTitle" />
                        ),
                        tooltip: (
                            <Translate content="projects.server.powerOff" />
                        ),
                    }}
                    handleConfirm={handlePowerOff}
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.SHUTDOWN)}
                />
            )}
            <ApplicationLoginLink settings={server.settings}/>
            {canChangeServersHostname && (
                <Popover
                    onClose={handleClose(POPOVERS.EDIT)}
                    visible={isPopoverOpened[POPOVERS.EDIT]}
                    canCloseOnOutsideClick={false}
                    target={(
                        <Button
                            ghost={true}
                            disabled={crIsInMaintenance}
                            onClick={handleOpen(POPOVERS.EDIT)}
                            tooltip={<Translate content="projects.server.links.edit" />}
                            icon={<Icon name={ICONS.PENCIL} size={iconSize} />}
                        />
                    )}
                    placement="bottom-left"
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.EDIT)}
                >
                    <ProjectServerForm server={server} onCreated={handleClose(POPOVERS.EDIT)} />
                </Popover>
            )}
            {canDelete && (
                <ButtonWithInputConfirmation
                    isLoading={server.is_deleting}
                    disabled={crIsInMaintenance || server.is_processing}
                    translations={{
                        text: (
                            <Translate
                                content='projects.server.removeServerText'
                                params={{ name: <CopyText isInline={true}><Text bold>{server.name}</Text></CopyText> }}
                            />
                        ),
                        confirmationButton: (
                            <Translate content="projects.server.confirmRemove" />
                        ),
                        title: (
                            <Translate content="projects.server.removeTitle" />
                        ),
                        label: (
                            <Translate content="projects.server.removeServerLabel" />
                        ),
                        tooltip: (<Translate content="projects.server.remove" />),
                    }}
                    confirmation={server.name}
                    handleConfirm={handleDelete}
                    icon={<Icon name={ICONS.RECYCLE} size={iconSize} />}
                    data-cy={dataCySelector(server.id, SERVER.ACTIONS.REMOVE)}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    push: ownProps.history.push,
    user: state.auth.user,
    canManageServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    canStartServers: hasPermission(state, PERMISSION_LIST.START_SERVERS),
    canStopServers: hasPermission(state, PERMISSION_LIST.STOP_SERVERS),
    canRestartServers: hasPermission(state, PERMISSION_LIST.RESTART_SERVERS),
    canPowerOffServers: hasPermission(state, PERMISSION_LIST.POWER_OFF_SERVERS),
    canDeleteServers: hasPermission(state, PERMISSION_LIST.DELETE_SERVERS),
    canOpenVncConsole: hasPermission(state, PERMISSION_LIST.OPEN_VNC_CONSOLE, PERMISSION_LIST.MANAGE_SERVERS),
    canChangeServersHostname: hasPermission(state, PERMISSION_LIST.CHANGE_SERVERS_HOSTNAME),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectServerActions: bindActionCreators(projectServerActions, dispatch),
});

export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(ProjectServerActions)));
