// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import * as projectServerActions from 'common/modules/computeResourceVm/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { isFeatureEnabled } from 'common/selectors';
import { FEATURE } from 'common/api/resources/Settings';
import { createProjectSshKey } from 'common/modules/project/actions';
import { ReinstallTab } from 'common/components/ServerTabs/ReinstallTab/ReinstallTab';
import { ISshKeyRequest } from 'common/api/resources/SshKey';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { generatePassword } from 'common/helpers/vm';
import * as toasterActions from 'common/modules/app/toaster/actions';

const mapStateToProps = (state: RootState) => ({
    server: state.project.servers.item,
    osImages: state.osImage.list,
    applications: state.application.list,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_REINSTALL_SERVER),
        isVmReinstalling: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_VM_ITEM) ||
            state.project.servers.item.status === ComputeResourceVmStatus.REINSTALLING,
    },
    hideUserData: isFeatureEnabled(FEATURE.HIDE_USER_DATA, state),
    sshKeys: state.project.projects.item.ssh_keys,
    mountDeps: [state.project.projects.item.id],
    buttonColor: state.settings.theme.primary_color,
    generatePasswd: generatePassword,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: RouteComponentProps<{ id: string }>) => {
    const id = parseInt(ownProps.match.params.id, 10);

    return {
        loadReinstallServerPageData: () => bindActionCreators(projectServerActions.loadReinstallServerPageData, dispatch)(id),
        reinstallServer: bindActionCreators(projectServerActions.reinstallComputeResourceVm, dispatch),
        createSshKey: (values: ISshKeyRequest) => bindActionCreators(createProjectSshKey, dispatch)(id, values),
        bakeToast: bindActionCreators(toasterActions.bakeForegroundToast, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReinstallTab));
