// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as vmActions from 'common/modules/computeResourceVm/actions';
import { combineReducers } from 'redux';
import { initialPlanState } from 'common/modules/plan/reducer';
import { initialLocationState } from 'common/modules/location/reducer';
import { initialUserState } from 'admin/user/reducer';
import { initialProjectState } from 'common/modules/project/reducer';
import { initialShortComputeResourceState } from 'admin/computeResource/reducers';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    BootMode,
    ComputeResourceVmCustomPlanRequest,
    ComputeResourceVmStatus,
    defaultBackupSettings,
    DiskCacheMode,
    DiskDriver,
    DiskImageType,
    IIpV4,
    IIpV6Range,
    IServerLimitResponse,
    IShortVmResponse,
    IUpdateComputeResourceVmEvent,
    IVmDiskResponse,
    IVmISOImageSettings,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import {
    IShortComputeResourceResponse,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import {
    ImageFormat,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    limitFactory,
    LimitName,
    NetworkTrafficLimitTypes,
    ResetLimitPolicy,
} from 'common/api/resources/Plan';
import {
    BandwidthUnit,
    DataUnit,
    DiskBandwidthUnit,
    GiB,
    IOpsUnit,
    Unit,
} from 'common/helpers/units';
import { ClusterImportProductType } from 'common/api/resources/ClusterImport';
import { OS_TYPES } from 'common/constants';
import { IsoChecksumMethod } from 'common/api/resources/IsoImage';
import { IComputeResourceVmVpcNetworkInterfaceResponse } from 'common/api/resources/VpcNetwork';

export interface IVmReducer {
    list: IPaginateApiResponse<IVmResponse[]>;
    item: IVmResponse;
    limits: IServerLimitResponse[];
    disks: IVmDiskResponse[];
    vpcNetworkInterfaces: IPaginateApiResponse<IComputeResourceVmVpcNetworkInterfaceResponse[]>;
}

export type VmActions = ActionType<typeof vmActions>;
export type VmState = Readonly<IVmReducer>;

export const initialShortComputeResourceVmState: IShortVmResponse = {
    id: 0,
    name: '',
    compute_resource: initialShortComputeResourceState,
};

export const defaultShortComputeResourceState: IShortComputeResourceResponse = {
    ...initialShortComputeResourceState,
};

export const initialComputeResourceVmState: IVmResponse = {
    id: 0,
    name: '',
    uuid: '',
    os_type: OS_TYPES.LINUX,
    description: '',
    status: ComputeResourceVmStatus.NO_STATE,
    virtualization_type: VirtualizationType.KVM,
    real_status: ComputeResourceVmStatus.NO_STATE,
    boot_mode: BootMode.DISK,
    is_processing: false,
    progress: 0,
    plan: initialPlanState,
    location: initialLocationState,
    has_incremental_backups: false,
    ips: [],
    ip_addresses: {
        ipv4: [],
        ipv6: [],
    },
    specifications: {
        ram: 0,
        disk: 0,
        vcpu: 0,
    },
    settings: {
        os_image: {
            icon: '',
            url: '',
            name: '',
            type: DiskImageType.OS_IMAGE,
            cloud_init_version: '',
        },
        user: '',
        mac_address: '',
        vnc_password: '',
        disk_cache_mode: DiskCacheMode.NONE,
        disk_driver: DiskDriver.SCSI,
        guest_tools_installed: true,
        guest_agent_available: true,
    },
    user: initialUserState,
    project: initialProjectState,
    usage: {
        cpu: 0,
        network: {
            incoming: {
                value: 0,
                is_exceeded: false,
            },
            outgoing: {
                value: 0,
                is_exceeded: false,
            },
        },
        disk: {
            actual_size: 0,
        },
    },
    compute_resource: initialShortComputeResourceState,
    compute_resource_name: initialShortComputeResourceVmState.name,
    is_suspended: false,
    is_backup_available: false,
    backup_settings: defaultBackupSettings,
    next_scheduled_backup_at: null,
    ssh_keys: [],
    created_at: '',
    vnc_url: 'example.com:8080/vnc-console/kvm/uuid',
    fqdns: [],
    product: ClusterImportProductType.SOLUS_VM_2,

    new_password: '',
    isLoading: false,
    is_deleting: false,
};

export const initialComputeResourceVmSettingIsoImageState: IVmISOImageSettings = {
    name: '',
    url: '',
    icon: '',
    os_type: OS_TYPES.LINUX,
    use_tls: true,
    checksum_method: IsoChecksumMethod.MD5,
    checksum: '',
    show_url_and_checksum: true,
    show_tls: true,
};

export const initialComputeResourceVmEventState: IUpdateComputeResourceVmEvent = {
    id: 0,
    status: ComputeResourceVmStatus.NO_STATE,
    real_status: ComputeResourceVmStatus.NO_STATE,
    is_processing: false,
    plan: initialPlanState,
    specifications: {
        ram: 0,
        disk: 0,
        vcpu: 0,
    },
    usage: initialComputeResourceVmState.usage,
    is_suspended: false,
    progress: 100,
    backup_settings: defaultBackupSettings,
    has_incremental_backups: false,
    ip_addresses: {
        ipv4: [],
        ipv6: [],
    },
};

export const initialCustomPlanState: ComputeResourceVmCustomPlanRequest = {
    params: {
        disk: 1,
        ram: GiB,
        vcpu: 1,
        vcpu_units: 1000,
        vcpu_limit: 100,
        io_priority: 4,
        swap: GiB,
    },
    virtualization_type: VirtualizationType.KVM,
    storage_type: StorageType.FB,
    image_format: ImageFormat.QCOW2,
    is_snapshots_enabled: false,
    reset_limit_policy: ResetLimitPolicy.Never,
    network_traffic_limit_type: NetworkTrafficLimitTypes.Separate,
    limits: {
        [LimitName.NetworkTotalTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkOutgoingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkIncomingTraffic]: limitFactory(DataUnit.GiB),
        [LimitName.NetworkReduceBandwidth]: limitFactory(BandwidthUnit.Kbps),
        [LimitName.NetworkOutgoingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.NetworkIncomingBandwidth]: limitFactory(BandwidthUnit.Mbps),
        [LimitName.DiskIops]: limitFactory(IOpsUnit.iops),
        [LimitName.DiskBandwidth]: limitFactory(DiskBandwidthUnit.Bps),
        [LimitName.BackupsNumber]: limitFactory(Unit.UNITS, 7, true),
        [LimitName.SnapshotsCount]: limitFactory(Unit.UNITS),
        [LimitName.SnapshotsSize]: limitFactory(DataUnit.GiB),
        [LimitName.AdditionalDiskNumber]: limitFactory(Unit.UNITS, 7, true),
    },
    is_backup_available: false,
    backup_settings: {
        is_incremental_backup_enabled: false,
        incremental_backups_limit: 3,
    },
    is_additional_ips_available: false,
    tokens_per_hour: 0,
    tokens_per_month: 0,
    ip_tokens_per_hour: 0,
    ip_tokens_per_month: 0,
    iso_image_tokens_per_hour: 0,
    iso_image_tokens_per_month: 0,
    backup_price: 0,
    is_custom: true,
    available_os_image_versions: [],
    available_applications: [],
    image_preset_ids: [],
};

export default combineReducers<VmState, VmActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(vmActions.updateVm):
            // todo https://webpros.atlassian.net/browse/SIO-2993
            return ({
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            ...action.payload,
                            usage: {
                                ...item.usage,
                                ...action.payload.usage,
                            },
                        };
                    }

                    return item;
                }),
            });
        case getType(vmActions.updateVmDiskUsage):
            return ({
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            usage: {
                                ...item.usage,
                                disk: {
                                    ...item.usage.disk,
                                    actual_size: action.payload.size,
                                },
                            },
                        };
                    }

                    return item;
                }),
            });
        case getType(vmActions.updateVmProgress):
            return updateInPaginated(state, {
                id: action.payload.id,
                progress: action.payload.progress,
            });
        case getType(vmActions.addVm):
            return addToPaginated(state, action.payload);
        case getType(vmActions.setVms):
            return action.payload;
        case getType(vmActions.setVm):
            return updateInPaginated(state, action.payload);
        case getType(vmActions.deleteVm):
            return deleteFromPaginated(state, action.payload);
        case getType(vmActions.setVmIsLoading):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: true };
                    }
                    return item;
                })],
            };
        case getType(vmActions.unsetVmIsLoading):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, isLoading: false };
                    }
                    return item;
                })],
            };
        case getType(vmActions.setVmItemIsDeleting):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        case getType(vmActions.moveVm):
            return {
                ...state,
                data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            compute_resource: action.payload.dst,
                            ips: action.payload.ips,
                        };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = { ...initialComputeResourceVmState }, action) => {
        switch (action.type) {
        case getType(vmActions.setVm):
            return action.payload;
        case getType(vmActions.unsetVm):
            return { ...initialComputeResourceVmState };
        case getType(vmActions.addVmIpV6ReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ...state.ip_addresses,
                    ipv6: state.ip_addresses.ipv6.map(
                        (ipv6): IIpV6Range => {
                            if (ipv6.id === action.payload.ip_id) {
                                return {
                                    ...ipv6,
                                    reverse_dns: [...ipv6.reverse_dns, action.payload],
                                };
                            }

                            return ipv6;
                        }
                    ),
                },
            };
        case getType(vmActions.deleteVmIpV6ReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6.map((ipv6): IIpV6Range => ({
                        ...ipv6,
                        reverse_dns: ipv6.reverse_dns.filter(item => item.id !== action.payload),
                    })),
                    ipv4: state.ip_addresses.ipv4,
                },
            };
        case getType(vmActions.updateVmReverseDns):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6.map((item: IIpV6Range): IIpV6Range => ({
                        ...item,
                        reverse_dns: item.reverse_dns.map((record: IReverseDnsResponse): IReverseDnsResponse => {
                            if (record.id === action.payload.id) {
                                return {
                                    ...record,
                                    ...action.payload.domain,
                                };
                            }

                            return record;
                        }),
                    })),
                    ipv4: state.ip_addresses.ipv4.map((item: IIpV4) => {
                        if (item.reverse_dns.id === action.payload.id) {
                            return {
                                ...item,
                                reverse_dns: {
                                    ...item.reverse_dns,
                                    ...action.payload.domain,
                                },
                            };
                        }

                        return item;
                    }),
                },
            };
        case getType(vmActions.updateVm):
            if (action.payload.id === state.id) {
                // todo https://webpros.atlassian.net/browse/SIO-2993
                return {
                    ...state,
                    ...action.payload,
                    usage: {
                        ...state.usage,
                        ...action.payload.usage,
                    },
                };
            }
            return state;
        case getType(vmActions.updateVmDiskUsage):
            if (action.payload.id === state.id) {
                return ({
                    ...state,
                    usage: {
                        ...state.usage,
                        disk: {
                            ...state.usage.disk,
                            actual_size: action.payload.size,
                        },
                    },
                });
            }
            return state;
        case getType(vmActions.updateVmProgress):
            if (action.payload.id === state.id) {
                return {
                    ...state,
                    progress: action.payload.progress,
                };
            }
            return state;
        case getType(vmActions.moveVm):
            if (state.id !== action.payload.id) {
                return state;
            }
            return {
                ...state,
                compute_resource: action.payload.dst,
            };
        case getType(vmActions.createVmAdditionalIp):
            // Don't attach the same IP more than once to the list.
            if (state.ip_addresses.ipv4.some(item => item.id === action.payload.id)) {
                return state;
            }

            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6,
                    ipv4: [
                        ...state.ip_addresses.ipv4,
                        action.payload,
                    ],
                },
            };
        case getType(vmActions.deleteVmAdditionalIp):
            return {
                ...state,
                ip_addresses: {
                    ipv6: state.ip_addresses.ipv6,
                    ipv4: state.ip_addresses.ipv4.filter(item => item.id !== action.payload),
                },
            };
        case getType(vmActions.updatePrimaryIp):
            return {
                ...state,
                ip_addresses: {
                    ...state.ip_addresses,
                    ipv4: state.ip_addresses.ipv4.map(item => ({
                        ...item,
                        is_primary: item.id === action.payload,
                    })),
                },
            };
        case getType(vmActions.setNewPassword):
            if (action.payload.id === state.id) {
                return {
                    ...state,
                    new_password: action.payload.new_password,
                };
            }
            return state;
        default:
            return state;
        }
    },
    limits: (state = [], action) => {
        switch (action.type) {
        case getType(vmActions.setServerLimits):
            return action.payload;
        default:
            return state;
        }
    },
    disks: (state = [], action) => {
        switch (action.type) {
        case getType(vmActions.setVmDisks):
            return action.payload;
        case getType(vmActions.createVmAdditionalDisk):
            return [
                ...state,
                action.payload,
            ];
        case getType(vmActions.updateVmAdditionalDisk):
            return state.map(item => item.id === action.payload.id ? action.payload : item);
        case getType(vmActions.deleteVmAdditionalDisk):
            return state.filter(item => item.id !== action.payload);
        case getType(vmActions.setVmAdditionalDiskIsDeleting):
            return state.map(item => item.id === action.payload.id ? {
                ...item,
                isDeleting: action.payload.isDeleting,
            } : item);
        default:
            return state;
        }
    },
    vpcNetworkInterfaces: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(vmActions.setVmVpcNetworkInterfaces):
            return action.payload;
        case getType(vmActions.createVmVpcNetworkInterface):
            return {
                ...state,
                data: [
                    ...state.data,
                    action.payload,
                ],
            };
        case getType(vmActions.deleteVmVpcNetworkInterface):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        default:
            return state;
        }
    },
});
