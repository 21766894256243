// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IPlanCreateRequest,
    IPlanLimit,
    IPlanResponse,
    limitFactory,
    LimitName,
} from 'common/api/resources/Plan';
import { ILocationResponse } from 'common/api/resources/Location';
import { IShortUserResponse } from 'common/api/resources/User';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    IShortComputeResourceResponse,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import { IApplicationLoginLink } from 'common/api/resources/Application';
import { ISshKeyResponse } from 'common/api/resources/SshKey';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';
import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import {
    IIpResponse,
    IpIssuedFor,
} from 'common/api/resources/Ip';
import { Unit } from 'common/helpers/units';
import {
    ISchedule,
    ScheduleType,
} from 'common/api/resources/model';
import { ClusterImportProductType } from 'common/api/resources/ClusterImport';
import { IpBlockType } from 'common/api/resources/IpBlock';
import { OS_TYPES } from 'common/constants';
import { IsoChecksumMethod } from 'common/api/resources/IsoImage';
import { IShortStorageResponse } from 'common/api/resources/Storage';
import { IShortOfferResponse } from 'common/api/resources/Offer';
import { IComputeResourceVmVpcNetworkInterfaceResponse } from 'common/api/resources/VpcNetwork';

export interface IVmCreateRequest {
    name: string;
    location: number;
    description: string;
    plan?: number;
    custom_plan?: ComputeResourceVmCustomPlanRequest;
    os?: number;
    application?: number;
    application_data?: Record<string, unknown>;
    password: string;
    ssh_keys: number[];
    user: number;
    project: number;
    user_data?: string;
    compute_resource?: number;
    backup_settings: IBackupSettings;
    fqdns?: string[];
    ip_types: IpBlockType[];
    primary_ip?: string;
    additional_ip_count?: number;
    additional_ipv6_count?: number;
    primary_disk_offer?: number;
    additional_disks?: IAdditionalDiskRequest[];
    vpc_networks?: number[];
}

export enum BootMode {
    DISK = 'disk',
    RESCUE = 'rescue',
    ISO_IMAGE = 'iso_image'
}

export interface IVmUpdateRequest {
    name?: string;
    description?: string;
    fqdns?: string[];
    backup_settings?: IBackupSettings;
    boot_mode?: BootMode;
    user_id?: number;
    project_id?: number;
    iso_image_id?: number;
}

// ToDo make sense to split it in two enums SIO-3212
export enum ComputeResourceVmStatus {
    RESIZING = 'resizing',
    RESTARTING = 'restarting',
    SUSPENDING = 'suspending',
    RESUMING = 'resuming',
    UPDATING = 'updating',
    DELETING = 'deleting',
    REINSTALLING = 'reinstalling',
    MIGRATING = 'migrating',
    BACKUP_RESTORING = 'restoring the backup',
    BACKUP_CREATING = 'creating the backup',
    SNAPSHOT_CREATING = 'creating the snapshot',
    SNAPSHOT_REVERTING = 'reverting the snapshot',
    SNAPSHOT_DELETING = 'deleting the snapshot',
    USAGE_RESETTING = 'resetting the usage',
    CHANGING_PASSWORD = 'changing the password',
    INSTALLING_GUEST_TOOLS = 'installing guest tools',
    CREATING = 'creating',
    STARTING = 'starting',
    STOPPING = 'stopping',
    STARTED = 'started',
    STOPPED = 'stopped',
    UNAVAILABLE = 'unavailable',
    NO_STATE = 'no state',
    NOT_EXIST = 'not exist',
    PAUSED = 'paused',
    ADDITIONAL_IP_ADDING = 'adding additional IP',
    ADDITIONAL_IP_DELETING = 'deleting additional IP',
    PRIMARY_IP_CHANGING = 'change primary IP',
    ADDITIONAL_DISK_ADDING = 'adding additional disk',
    ADDITIONAL_DISK_DELETING = 'deleting additional disk',
    ADDITIONAL_DISK_RESIZING = 'resizing additional disk',
    ATTACHING_TO_VPC_NETWORK = 'attaching to VPC network',
    DETACHING_FROM_VPC_NETWORK = 'detaching from VPC network',
}

// Should be synced with `backend/api/v1/ComputeResourceVm/DTO/VmBackupSettingsDTO.php`
export interface IBackupSettings {
    enabled: boolean;
    schedule: ISchedule;
    limit?: IPlanLimit;
}

export const defaultBackupSettings: IBackupSettings = {
    enabled: false,
    schedule: {
        type: ScheduleType.DAILY,
        time: {
            hour: 0,
            minutes: 0,
        },
        days: [],
    },
    limit: limitFactory(Unit.UNITS),
};

// Should be synced with `backend/api/v1/ComputeResourceVm/Enums/DiskImageType.php`
export enum DiskImageType {
    OS_IMAGE = 'os_image',
    APPLICATION = 'application',
}

// Should be synced with `backend/api/v1/ComputeResourceVm/Enums/DiskCacheMode.php`
export enum DiskCacheMode {
    NONE = 'none',
    DEFAULT = 'default',
    WRITEBACK = 'writeback',
    WRITETHROUGH = 'writethrough',
    DIRECTSYNC = 'directsync',
    UNSAFE = 'unsafe',
}

export enum DiskDriver {
    SCSI = 'scsi',
    VIRTIO = 'virtio',
    IDE = 'ide',
    SATA = 'sata',
}

// Should be synced with `backend/api/v1/ComputeResourceVm/Enums/OsFirmware.php`
export enum OsFirmware {
    BIOS = 'bios',
    EFI = 'efi',
}

export interface IVmSettings {
    os_image: {
        name: string;
        icon: string;
        url: string;
        type: DiskImageType;
        cloud_init_version: string;
    };
    user: string;
    mac_address: string;
    vnc_password: string;
    // This property contains generated login link which should be used for login to
    // application.
    application_login_link?: IApplicationLoginLink;
    vnc_enabled?: boolean;
    netfilter?: {
        value: VZNetfilterStatus;
        is_editable: boolean;
    };
    tun_tap?: {
        value: boolean;
        is_editable: boolean;
    };
    ppp?: {
        value: boolean;
        is_editable: boolean;
    };
    disk_cache_mode?: DiskCacheMode;
    disk_driver?: DiskDriver;
    guest_tools_installed?: boolean;
    guest_agent_available?: boolean;
    iso_image?: IVmISOImageSettings;
    firmware?: OsFirmware;
}

export interface IVmISOImageSettings {
    name: string;
    url: string;
    icon: string;
    os_type: OS_TYPES;
    use_tls: boolean;
    checksum_method: IsoChecksumMethod;
    checksum: string;
    show_url_and_checksum: boolean;
    show_tls: boolean;
}

// Should be synced with `backend/api/v1/Plan/Enums/VZNetfilterStatus.php`
export enum VZNetfilterStatus {
    DISABLED = 'disabled',
    STATELESS = 'stateless',
    STATEFUL = 'stateful',
    FULL = 'full',
}

export type VirtualServerSettingsRequest = IKVMVirtualServerSettingsRequest & IVZVirtualServerSettingsRequest;

export interface IKVMVirtualServerSettingsRequest {
    disk_cache_mode?: DiskCacheMode;
    disk_driver?: DiskDriver;
    firmware?: OsFirmware;
}

export interface IVZVirtualServerSettingsRequest {
    vnc_enabled?: boolean;
    netfilter?: VZNetfilterStatus;
    tun_tap?: boolean;
    ppp?: boolean;
}

export interface ISpecifications {
    vcpu: number;
    disk: number;
    ram: number;
}

export interface IAdditionalIpAddSuccessedEvent {
    ip: IIpV4;
}

export interface IAdditionalIpAddFailedEvent {
    ipId: number;
}

export interface IAdditionalIpDeletedEvent {
    deletedIpId: number;
}

export interface IAdditionalIpRequest {
    count?: number;
    ip?: string;
    type?: IpBlockType;
}

export interface IPrimaryIpChangedSuccessEvent {
    newPrimaryIpId: number;
}

export interface IUpdateServerIpsRequest {
    primary_id: number;
}

export interface IIpV4 {
    id: number;
    is_reverse_dns_enabled: boolean;
    ip: string;
    reverse_dns: IReverseDnsResponse;
    is_primary: boolean;
    issued_for: IpIssuedFor;
    gateway: string;
    netmask: string;
    cidr: string;
    ns_1: string;
    ns_2: string;
}

export interface IIpV6Range {
    id: number;
    range: string;
    is_primary: boolean;
    issued_for: IpIssuedFor;
    is_reverse_dns_enabled: boolean;
    reverse_dns: IReverseDnsResponse[];
    gateway: string;
    ns_1: string;
    ns_2: string;
}

export interface IIpAddresses {
    ipv4: IIpV4[];
    ipv6: IIpV6Range[];
}

export interface INetworkTraffic {
    is_exceeded: boolean;
    value: number;
}

interface IVmUsage {
    cpu: number;
    network: {
        incoming: INetworkTraffic;
        outgoing: INetworkTraffic;
    };
    disk?: {
        actual_size: number;
    };
}

export interface IServerLimitResponse {
    name: LimitName;
    limit: IPlanLimit;
    used: number;
    is_exceeded: boolean;
}

export interface IShortVmResponse {
    id: number;
    name: string;
    uuid?: string;
    compute_resource?: IShortComputeResourceResponse;
}

export interface IVmResponse {
    id: number;
    name: string;
    uuid: string;
    os_type: OS_TYPES;
    description: string;
    status: ComputeResourceVmStatus;
    real_status: ComputeResourceVmStatus;
    boot_mode: BootMode;
    is_processing: boolean;
    progress: number;
    virtualization_type: VirtualizationType;
    specifications: ISpecifications;
    plan: IPlanResponse;
    location?: ILocationResponse | null;
    /** @deprecated */
    ips: IIpResponse[];
    settings: IVmSettings;
    user: IShortUserResponse;
    project: IProjectResponse;
    ip_addresses: IIpAddresses;
    usage: IVmUsage;
    compute_resource?: IShortComputeResourceResponse;
    compute_resource_name?: string;
    is_suspended: boolean;
    is_backup_available: boolean;
    has_incremental_backups: boolean;
    backup_settings: IBackupSettings;
    next_scheduled_backup_at: string | null;
    ssh_keys: ISshKeyResponse[];
    created_at: string;
    vnc_url: string;
    fqdns: string[];
    product: ClusterImportProductType;
    new_password?: string;
    isLoading?: boolean;
    is_deleting?: boolean;
}

export interface IUpdateComputeResourceVmEvent {
    id: number;
    status: ComputeResourceVmStatus;
    real_status: ComputeResourceVmStatus;
    is_suspended: boolean;
    is_processing: boolean;
    progress: number;
    plan: IPlanResponse;
    specifications: ISpecifications;
    usage: IVmUsage;
    backup_settings: IBackupSettings;
    has_incremental_backups: boolean;
    ip_addresses: IIpAddresses;
}

export interface IUpdateComputeResourceVm {
    computeResourceVm: IUpdateComputeResourceVmEvent;
}

export interface IUpdateServerLimits {
    limits: IServerLimitResponse[];
}

export interface IUpdateDiskUsageComputeResourceVm {
    id: number;
    size: number;
}

export interface IUpdatePasswordComputeResourceVm {
    id: number;
    password: string;
}

export interface IMoveComputeResourceVm {
    id: number;
    dst: IShortComputeResourceResponse;
    ips: IIpResponse[];
}

export interface IVmActionRequest {
    force: boolean;
}

export interface IVmResetPasswordRequest {
    send_password_to_current_user?: boolean;
}

export interface IVmResizeRequest {
    plan_id?: number;
    custom_plan?: ComputeResourceVmCustomPlanRequest;
    preserve_disk: boolean;
    backup_settings: IBackupSettings;
}

export interface IVmReinstallRequest {
    os?: number;
    application?: number;
    ssh_keys: number[];
    user_data?: string;
    application_data?: Record<string, unknown>;
    password: string;
}

export interface IComputeResourceVmListFilters {
    status?: string;
    compute_resource_id?: number | number[];
    user_id?: number;
    virtualization_type?: string;
    tags?: string;
}

export interface IComputeResourceVmListRequest extends IRequest<IComputeResourceVmListFilters>, IPaginated {}

export type ComputeResourceVmCustomPlanRequest = Omit<
    IPlanCreateRequest,
    'name'
    | 'is_visible'
    | 'available_locations'
> & Partial<Pick<IPlanCreateRequest, 'available_os_image_versions' | 'available_applications' | 'image_preset_ids'>> & {
    is_custom: true;
};

export type ComputeResourceVmCustomPlanResponse = Omit<
    IPlanResponse,
    'id'
    | 'name'
    | 'is_visible'
    | 'available_locations'
    | 'is_default'
    | 'is_snapshot_available'
    | 'is_loading'
    | 'is_deleting'
    | 'is_thin_provisioned'
    | 'position'
> & Partial<Pick<IPlanResponse, 'available_os_image_versions' | 'available_applications'>> & {
    is_custom: true;
};

export interface IComputeResourceVmBackupListFilters {
    id: number;
}

export interface IComputeResourceVmBackupListRequest
    extends IRequest<IComputeResourceVmBackupListFilters>, IPaginated {}

export interface IVmDiskResponse {
    id: number;
    is_primary: boolean;
    name: string;
    size: number;
    actual_size: number;
    path: string;
    full_path?: string;
    offer: IShortOfferResponse;
    storage?: IShortStorageResponse;

    isLoading?: boolean; // ???
    isDeleting?: boolean;
}

export interface IAdditionalDiskRequest {
    name: string;
    size: number;
    offer_id: number;
}

export interface IAdditionalDiskUpdateRequest {
    name: string;
}

export interface IAdditionalDiskResizeRequest {
    size: number;
}

export interface IVmDiskEvent {
    disk: IVmDiskResponse;
}

export interface IVmDiskRemovedEvent {
    diskId: number;
}

export interface IVpcNetworkAttachSucceedEvent {
    vpcNetworkInterface: IComputeResourceVmVpcNetworkInterfaceResponse;
}

export interface IVpcNetworkAttachFailedEvent {
    vpcNetworkInterfaceId: number;
}

export interface IVpcNetworkDetachSucceedEvent {
    vpcNetworkInterfaceId: number;
}

export interface IVpcNetworkDetachFailedEvent {
    vpcNetworkInterfaceId: number;
}