// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ReactNode } from 'react';
import {
    Form,
    SelectOption,
    Text,
    Translate,
    FormFieldSelect,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import * as licenseActions from 'admin/license/solusVM/actions';
import {
    ISolusVMLicenseComputeResourceItem,
    IAssignComputeResourcesLicenseTypes,
} from 'common/api/resources/License/solusVM';
import { FormContainer } from 'admin/license/solusVM/containers/Styles';
import { HTTP_CODES } from 'common/api/constants';
import {
    transformErrors as baseTransformError,
    IValidatorError,
} from 'common/validator';
import { LabelWithButton } from 'admin/common/components/SelectWithDataLoader/Styles';
import { LicenseType } from 'common/api/resources/ComputeResource';
import FormErrors from 'common/components/Form/FormErrors/FormErrors';

export type ComputeResourcesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IAssignComputeResourcesLicenseTypes = {
    standard: [],
    mini: [],
    micro: [],
};

export const ComputeResources: React.FC<ComputeResourcesProps> = ({
    isLoading,
    errors,
    formErrorsActions: { setFormErrors, clearFormErrors },
    licenseActions: { getLicenseComputeResources, assignComputeResources },
    computeResources: groupedComputeResources,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IAssignComputeResourcesLicenseTypes>(defaultValues);
    const [computeResources, setComputeResources] = React.useState<ISolusVMLicenseComputeResourceItem[]>([]);

    React.useEffect(() => {
        getLicenseComputeResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setSubmitValues({
            standard: groupedComputeResources.standard.map(cr => cr.id),
            mini: groupedComputeResources.mini.map(cr => cr.id),
            micro: groupedComputeResources.micro.map(cr => cr.id),
        });
        setComputeResources([
            ...groupedComputeResources.standard,
            ...groupedComputeResources.mini,
            ...groupedComputeResources.micro,
        ]);
    }, [groupedComputeResources]);

    const handleSubmit = async (values: IAssignComputeResourcesLicenseTypes) => {
        clearFormErrors();

        const validationErrors = validateValues(values);
        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await assignComputeResources({ license_types: values });
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                setFormErrors(transformErrors(e.response.data.errors));
            }

            throw e;
        }
    };

    const validateValues = (values: IAssignComputeResourcesLicenseTypes): Record<string, ReactNode[]> => {
        const providedIds = new Set<number>([
            ...values.standard,
            ...values.micro,
            ...values.mini,
        ]);
        const notProvidedCrs = computeResources.filter(cr => !providedIds.has(cr.id));

        if (notProvidedCrs.length === 0) {
            return {};
        }

        return {
            license_types: [
                <Translate
                    content="license.computeResourcesForm.notListed"
                    params={{
                        crs: (<Text bold>{notProvidedCrs.map(cr => cr.name).join(', ')}</Text>),
                    }}
                />,
            ],
        };
    };

    const transformErrors = (responseErrors: IValidatorError) => {
        const result = {};
        Object.entries(responseErrors).forEach(([ key, value ]) => {
            result[key.replace('license_types.', '')] = value;
        });

        return baseTransformError(result);
    };

    const handleOnFieldChange = (field: string, value: number[]) => {
        setSubmitValues(prev => (
            Object.keys(prev)
                .reduce((result, licenseType) => ({
                    ...result,
                    [licenseType]: licenseType === field
                        ? value
                        : prev[licenseType].filter((id: number) => !value.includes(id)),
                }), prev)
        ));
    };

    const handleOnSelectAll = (field: LicenseType) => () => {
        setSubmitValues(prev => (
            Object.keys(prev)
                .reduce((result, licenseType) => ({
                    ...result,
                    [licenseType]: licenseType === field
                        ? computeResources.map(cr => cr.id)
                        : [],
                }), prev)
        ));
    };

    const computeResourceOptions = computeResources.map(cr => (<SelectOption key={cr.id} value={cr.id}>{cr.name}</SelectOption>));

    return (
        <FormContainer>
            <Form
                id="computeResourcesForm"
                onSubmit={handleSubmit}
                onFieldChange={handleOnFieldChange}
                values={submitValues}
                errors={errors}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <FormFieldSelect
                    searchable={true}
                    name="standard"
                    label={(
                        <LabelWithButton>
                            <Translate content="license.computeResourcesForm.standardLabel" />
                            <Button
                                ghost={true}
                                intent={INTENT_TYPE.PRIMARY}
                                state={isLoading ? 'loading' : undefined}
                                onClick={handleOnSelectAll(LicenseType.STANDARD)}
                                disabled={false}
                            >
                                <Translate content="license.computeResourcesForm.selectAllBtn" />
                            </Button>
                        </LabelWithButton>
                    )}
                    multiple
                    size="fill"
                    value={submitValues.standard}
                    disabled={isLoading}
                >
                    {computeResourceOptions}
                </FormFieldSelect>
                <FormFieldSelect
                    searchable={true}
                    name="mini"
                    label={(
                        <LabelWithButton>
                            <Translate content="license.computeResourcesForm.miniLabel" />
                            <Button
                                ghost={true}
                                intent={INTENT_TYPE.PRIMARY}
                                state={isLoading ? 'loading' : undefined}
                                onClick={handleOnSelectAll(LicenseType.MINI)}
                                disabled={false}
                            >
                                <Translate content="license.computeResourcesForm.selectAllBtn" />
                            </Button>
                        </LabelWithButton>
                    )}
                    multiple
                    size="fill"
                    value={submitValues.mini}
                    disabled={isLoading}
                >
                    {computeResourceOptions}
                </FormFieldSelect>
                <FormFieldSelect
                    searchable={true}
                    name="micro"
                    label={(
                        <LabelWithButton>
                            <Translate content="license.computeResourcesForm.microLabel" />
                            <Button
                                ghost={true}
                                intent={INTENT_TYPE.PRIMARY}
                                state={isLoading ? 'loading' : undefined}
                                onClick={handleOnSelectAll(LicenseType.MICRO)}
                                disabled={false}
                            >
                                <Translate content="license.computeResourcesForm.selectAllBtn" />
                            </Button>
                        </LabelWithButton>
                    )}
                    multiple
                    size="fill"
                    value={submitValues.micro}
                    disabled={isLoading}
                >
                    {computeResourceOptions}
                </FormFieldSelect>
                <Button
                    type="submit"
                    form="computeResourcesForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                    isLoading={isLoading}
                >
                    <Translate content="license.computeResourcesForm.updateBtn" />
                </Button>
                {errors.license_types && <FormErrors errors={errors.license_types}/>}
            </Form>
        </FormContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    computeResources: state.solusVMLicense.computeResources,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ASSIGN_COMPUTE_RESOURCES),
    errors: state.app.formErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResources);
